<template>
  <v-container fluid>
    <member-index></member-index>
  </v-container>
</template>

<script>
  export default {
    name: 'Members',
    components: {
      MemberIndex: () => import ('@/components/members/MemberIndex.vue')
    },
  }
</script>

<style lang="scss" scoped>

</style>